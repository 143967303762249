.hot-coins .slick-slider{
  height: 70px;
}

@media (max-width: 500px) {
  .hot-coins .slick-slider{
    height: 55px;
  }
}

.side-light2 {
  /* width: 300px; */
  height: 200px;
  width: 1000px;
  /* Adjust as needed */
  height: 1000px;
  border-radius: 100%;
  background: radial-gradient(circle at 50%, #f2f23017 0%, transparent 50%);
  z-index: 1;
  overflow: hidden;
}

.side-light-brighter {
  /* width: 300px; */
  height: 200px;
  width: 1000px;
  /* Adjust as needed */
  height: 1000px;
  border-radius: 100%;
  background: radial-gradient(circle at 50%, #f2f23030 0%, transparent 50%);
  z-index: 1;
  overflow: hidden;
}

@media (max-width: 1000px) {
  .side-light2 {
    width: 700px;
    height: 700px;
  }
}

@media (max-width: 600px) {
  /* .side-light2 {
    width: 300px;
    height: 300px;
  } */
}

.graph-up svg line{
  /* filter: hue-rotate(85deg) saturate(80%) brightness(0.75) */
  stroke: #10b981 !important;
}
.graph-down svg line{
  /* filter: hue-rotate(300deg) saturate(210%) brightness(0.7) contrast(170%) */
  stroke:#ff5724 !important
}
.sparkline-chart svg line{
  stroke-width: 3 !important;
}
.sparkline-chart svg{
  height: 35px;
  width: 120px
}
