@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('non.geist');

body {
  font-family: "Inter", sans-serif;
  overflow-x: hidden;
  background-color: #11161c;
  /* background: linear-gradient(180deg, #0E141C 0%, #0E1C24 100%); */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.slick-arrow.slick-prev:before,
.slick-arrow.slick-next:before {
  font-size: "48px";
}

.react-multi-carousel-track {
  display: flex;
  gap: 24px;
}

.react-multi-carousel-item {
  margin: 0;
  width: fit-content;
}

.react-multi-carousel-dot-list {
  left: 50% !important;
  top: 100%;
  transform: translateX(-50%) !important;
  padding: 12px !important;

}
.carouselTrack{
  /* justify-content: center; */
}
.carouselDots {
  border-radius: 16px;
  background: #21262D;
  backdrop-filter: blur(4px);
  width: fit-content;
}

.carouselDots li {
  display: flex;
  align-items: center;
}

.carouselDots li button {
  width: 20px;
  height: 8px;
  border: none;
  border-radius: 4px;
  background-color: #000;
}

.carouselDots li:last-child button {
  margin: 0;
}

.react-multi-carousel-list {
  overflow: visible !important;
  z-index: 0;
}

.react-multiple-carousel__arrow {
  background-color: #21262D !important;
}

.react-multiple-carousel__arrow::before{
  font-size: 15px !important;
}

.react-multiple-carousel__arrow:hover{
  /* font-size: 15px !important; */
  color: #f2f230;
}

.react-multiple-carousel__arrow--left {
  right: calc(4% + 61px) !important;
  left: auto !important;
  top: 107%;
}

.react-multiple-carousel__arrow--right {
  right: calc(4% + 1px) !important;
  top: 107%;
}

.react-multi-carousel-dot--active button {
  background-color: #f2f230 !important;
}

.fade-bottom {
  position: relative;
}

.fade-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90px;
  /* Adjust this value as needed */
  background: linear-gradient(to bottom, transparent, #11171c);
  /* You can adjust the colors here */
}

.side-light {
  position: absolute;
  bottom: 50%;
  right: 60%;
  width: 1500px;
  /* Adjust as needed */
  height: 100%;
  border-radius: 100%;
  background: radial-gradient(circle at 50%, #f2f23017 0%, transparent 50%);
  z-index: -4;
  overflow: hidden;
}

.trade-side-light {
  position: absolute;
  bottom: 50%;
  right: 60%;
  width: 1500px;
  /* Adjust as needed */
  height: 100%;
  border-radius: 100%;
  background: radial-gradient(circle at 50%,
      #f2f23050 0%,
      #f2f23009 40%,
      transparent 50%);
  z-index: -4;
  overflow: hidden;
}

.trade-details-side-light {
  position: absolute;
  right: 70%;
  width: 1500px;
  /* Adjust as needed */
  height: 1000px;
  border-radius: 100%;
  background: radial-gradient(circle at 50%,
      #f2f23050 0%,
      #f2f23009 40%,
      transparent 50%);
  overflow: hidden;
}

.hiddenElement {
  opacity: 0;
  transition: all 1s ease-in;
}

.fadeIn {
  animation: fadeIn 1.3s ease-in-out;
}

.fadeInTrade {
  animation: fadeIn 0.3s ease-in-out;
}

.fadeOut {
  animation: fadeOut 1.5s ease-in-out;
}

.openningSoon {
  animation: openWarning 1s ease-in-out;
}

@keyframes openWarning {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media (max-width: 1000px) {
  .side-light {
    width: 700px;
    height: 700px;
  }
}

@media (max-width: 600px) {
  .side-light {
    width: 400px;
    height: 700px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes expandWidth {
  0% {
    width: auto;
    max-width: 1476px;
    margin-top: 32px;
  }

  100% {
    width: auto;
    max-width: 100%;
    margin-top: 0;
  }
}

@keyframes contractWidth {
  0% {
    width: auto;
    max-width: 100%;
    margin-top: 0;
  }

  100% {
    width: auto;
    max-width: 1476px;
    margin-top: 32px;
  }
}

@keyframes expandWidth2 {
  0% {
    width: auto;
    max-width: 1200px;
    margin-top: 32px;
  }

  100% {
    width: auto;
    max-width: 100%;
    margin-top: 0;
  }
}

@keyframes contractWidth2 {
  0% {
    width: auto;
    max-width: 100%;
    margin-top: 0;
  }

  100% {
    width: auto;
    max-width: 1200px;
    margin-top: 32px;
  }
}

@keyframes expandWidth3 {
  0% {
    width: auto;
    max-width: 944px;
    margin-top: 32px;
  }

  100% {
    width: auto;
    max-width: 100%;
    margin-top: 0;
  }
}

@keyframes contractWidth3 {
  0% {
    width: auto;
    max-width: 100%;
    margin-top: 0;
  }

  100% {
    width: auto;
    max-width: 944px;
    margin-top: 32px;
  }
}

.width-transition {
  animation: expandWidth 0.3s ease-in-out;
}

.transition-width-nav {
  animation: contractWidth 0.3s ease-in-out;
}

.width-transition2 {
  animation: expandWidth2 0.3s ease-in-out;
}

.transition-width-nav2 {
  animation: contractWidth2 0.3s ease-in-out;
}

.width-transition3 {
  animation: expandWidth3 0.3s ease-in-out;
}

.transition-width-nav3 {
  animation: contractWidth3 0.3s ease-in-out;
}

.parallax-section {
  position: relative;
  height: 120vh;
  overflow: hidden;
}

.parallax-section-1 {
  position: relative;
  height: 120vh;
  overflow: hidden;
}

.parallax-section-2 {
  position: relative;
  height: 120vh;
  overflow: hidden;
}

.parallax-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transition: transform 0.3s ease;
  transform: translateZ(0);
  /* Hardware acceleration */
}

@media (max-height: 700px) {
  .parallax-section {
    height: 160vh;
  }

  .parallax-section-1 {
    height: 200vh;
  }

  .parallax-section-2 {
    height: 170vh;
  }
}

@media (max-height: 785px) {
  .parallax-section {
    height: 160vh;
  }
}

@media (max-height: 605px) {
  .parallax-section {
    height: 200vh;
  }
}

@media (max-width: 785px) {
  .parallax-section-1 {
    height: 330vh;
  }

  .parallax-section-2 {
    height: 250vh;
  }

  .parallax-section {
    height: 160vh;
  }
}

@media (max-width: 1000px) {
  .parallax-section-1 {
    height: 290vh;
  }
}

.floatCoins {
  animation: float 3s ease-in-out infinite;
}

.floatCoins1 {
  animation: float 4s ease-in-out infinite;
}

.floatCoins2 {
  animation: float 2s ease-in-out infinite;
}

.logoFloat {
  animation: simplefloat 2s ease-in-out infinite;
}

@keyframes simplefloat {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.4);
    transform: translateY(0px);
  }

  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translateY(-8px);
  }

  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.4);
    transform: translateY(0px);
  }
}

@keyframes openMobileNav {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes closeMobileNav {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.backgroundSidesTransparent {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(to right,
      transparent,
      rgba(255, 255, 255, 1),
      transparent);
}

/* Styles for the container with scrollable content */
.scroll-container {
  width: 300px;
  height: 300px;
  overflow: auto;
  scrollbar-width: thin;
  /* Firefox */
  scrollbar-color: #999 transparent;
  /* Firefox */
}

.mobileNavAnimation {
  animation: openMobileNav 1s ease;
}

.mobileNavAnimation2 {
  animation: closeMobileNav 1s ease;
}

.mobileNavbBgFadeout {
  animation: fadeOut 1s ease;
}

.carousel .thumbs-wrapper.axis-vertical {
  display: none;
}

/* blog-text */

.blog-text p {
  font-size: 19px;
}

.blog-text h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 23px 0;
}

.blog-text h1 {
  font-size: 42px;
}

.blog-text h2 {
  font-size: 34px;
}

.blog-text h3 {
  font-size: 28.72px;
}

.blog-text h4 {
  font-size: 26px;
}

.blog-text h5 {
  font-size: 23/28px;
}

.blog-text h6 {
  font-size: 20.72px;
}

.blog-text a {
  color: #f2f230;
  text-decoration: underline;
}

@media (max-width: 944px) {
  .blog-text p {
    font-size: 17px;
  }

  .blog-text h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 15px 0;
  }

  .blog-text h1 {
    font-size: 36px;
  }

  .blog-text h2 {
    font-size: 26px;
  }

  .blog-text h3 {
    font-size: 20.72px;
  }

  .blog-text h4 {
    font-size: 18px;
  }

  .blog-text h5 {
    font-size: 16/28px;
  }

  .blog-text h6 {
    font-size: 14.72px;
  }
}

@media (max-width: 687px) {
  .centerCards {
    justify-content: center;
  }
}

/* Styles for the custom scrollbar track */
::-webkit-scrollbar {
  display: fixed;

  left: 0;
  width: 8px;
  height: 8px;
  scrollbar-width: thin;
  /* Firefox */
  scrollbar-color: #999 transparent;
  /* Firefox */
}

/* Styles for the custom scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #9999997e;
  transition: all 0.3s ease;
  border-radius: 10px;
  cursor: pointer;
}

/* Styles for the custom scrollbar track in Firefox */
::-webkit-scrollbar-track {
  /* background-color: #10151C; */
  background-color: transparent;
}

/* Styles for the custom scrollbar thumb in Firefox */
::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.details-input div {
  width: 64px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 25px;
  transition: 0.5s ease;
  border-right: solid 1px #475467;
  font-size: 14px;
  font-weight: 500;
}

.details-input input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.details-input .clicked {
  /* width: 54px; */
  border-radius: 6px;
  background: var(--Primary, linear-gradient(0deg, #f2f230 0%, #f2f230 100%));
  border: none;
  color: var(--gray-800, #1d2939);
  font-weight: 600;
}

@media (max-width: 640px) {
  .details-input div {
    width: 42px;
    height: 30px;
    font-size: 12px;
  }
}

/* React Popup */
.popup-content {
  background-color: #09090B !important;
  border: 1px solid #FFFFFF1A !important;
  border-radius: 12px;
  color: white;
  padding: 16px !important;
  width: 262px !important;
}

.popup-arrow {
  color: #09090B !important;
  stroke: #FFFFFF1A !important;
}


/* React Slick  */
@media (max-width: 768px) {
  .slick-track {
    display: flex !important;
    gap: 8px !important;
    align-items: center !important;
  }
  .slick-next {
    right: -20px !important;
  }
  .slick-prev {
    left: -20px !important;
  }
}

.popup-niza-verify-content {
  background-color: #0E0E10 !important;
  border: unset !important;
  padding: 40px !important;
  width: 644px !important;
}

.popup-niza-verify-margin-content {
  margin-top: 110px !important;
}

@media (max-width: 640px) {
  .popup-niza-verify-content {
    width: 100% !important;
    margin: auto 16px !important;
  }
  .popup-niza-verify-margin-content {
    margin: auto 16px !important;
    margin-top: auto !important;
  }
}